import { gql } from "@apollo/client";

export const GENERATE_POLICY = gql`
  mutation GeneratePolicy($input: GeneratePolicyInput!) {
    generatePolicy(input: $input) {
      content
      yaml
      policyMrns
    }
  }
`;
